<template>
  <v-container>
    <v-card elevation="1" class="mx-0 mt-6">
      <v-row>
      <v-col cols="2">
      <v-navigation-drawer permanent width="100%">
        <v-list-item color="primary" v-for="role in roles" :key="role.id" :to="{ name: 'RoleForm', params: { id: role.id } }">
          <v-list-item-title>{{ role.name }}</v-list-item-title>
        </v-list-item>
      </v-navigation-drawer>
      </v-col>
      <v-col cols="10" class="d-flex align-center justify-center" style="min-height:600px">
        <div v-if="!$route.params.id">
          Bitte wähle eine Benutzerrolle.
        </div>
        <router-view v-else :key="$route.path" :adminData="adminData"></router-view>
      </v-col>
      </v-row>
    </v-card>
    <v-btn
      color="primary"
      elevation="4"
      fab
      large
      :to="{
        name: 'RoleForm',
        params: { id: 'new' },
      }"
      class="fab-new"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </v-container>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
export default {
    computed: {
    ...mapGetters(["current","roles"]),
    },
    props: ["adminData"]
  //  beforeRouteEnter(to, from, next) {
  //   if (store.getters.current.permissions.ROLES_MANAGE) {
  //     next();
  //   } else {
  //     next({ name: "NotFound" });
  //   }
  // }
}
</script>

<style>

</style>